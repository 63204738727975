import React from "react";

const ErrorPage = () => (
  <div className="page">
    <div className="page-body">
      <p className="body-text">Page Not Found :/</p>
    </div>
  </div>
);

export default ErrorPage;
